import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import Board from "../components/block/Board";
import BackgroundImage from "../components/block/BackgroundImage";

import cloudOne from "../images/background/cloud-1.png";
import cloudTwo from "../images/background/cloud-2.png";
import cloudThree from "../images/background/cloud-3.png";
import cloudFour from "../images/background/cloud-4.png";
import cloudFive from "../images/background/cloud-5.png";

import { content } from "../data/data";

export default function Game(props) {
  const [cloudsZoomedIn, setCloudsZoomedIn] = useState(true);

  useEffect(() => {  
    setTimeout(() => {
      setCloudsZoomedIn(false)
    }, 500)
  }, []);

  return (
    <BackgroundImage>      
      {content.courses && <Board />}
      <CloudsLeft className={`clouds-zoomed-in-${cloudsZoomedIn}`}>
        <CloudOne className={`clouds-zoomed-in-${cloudsZoomedIn}`} />
        <CloudThree className={`clouds-zoomed-in-${cloudsZoomedIn}`} />
        <CloudFive className={`clouds-zoomed-in-${cloudsZoomedIn}`} />
      </CloudsLeft>
      <CloudsRight className={`clouds-zoomed-in-${cloudsZoomedIn}`}>
        <CloudTwo className={`clouds-zoomed-in-${cloudsZoomedIn}`} />
        <CloudFour className={`clouds-zoomed-in-${cloudsZoomedIn}`} />
      </CloudsRight>      
    </BackgroundImage>
  );
}

const CloudsLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  opacity: 1;
  transition: transform 1.25s ease-in-out, opacity 2s linear 0.5s;

  &.clouds-zoomed-in-false {
    transform: translateX(-100%);
    opacity: 0;
    pointer-events: none;
  }
`;

const CloudsRight = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: transform 1.25s ease-in-out, opacity 2s linear 0.5s;

  &.clouds-zoomed-in-false {
    transform: translateX(100%);
    opacity: 0;
    pointer-events: none;
  }
`;

const cloud = css`
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  width: 50vw;
  height: 50vh;
`;

const animatedCloud = css`
  z-index: 20;
  transform-origin: center;
  opacity: 1;
  transition: transform 2s ease-in-out, opacity 1s ease-in-out 1s;
  transform: scale(3);
`;

const CloudOne = styled.div`
  ${cloud}
  ${animatedCloud}
  top: -10%;
  left: -30%;
  animation: cloudOne 14s ease-in-out infinite;
  background-image: url(${cloudOne});  

  &.clouds-zoomed-in-false {
    opacity: 0.025;    
  }
`;

const CloudTwo = styled.div`
  ${cloud}
  ${animatedCloud}
  top: 0%;
  right: -10%;
  animation: cloudTwo 14s ease-in-out infinite;
  background-image: url(${cloudTwo});  

  &.clouds-zoomed-in-false {
    opacity: 0.025;    
  }
`;

const CloudThree = styled.div`
  ${cloud}
  ${animatedCloud}
  bottom: -40%;
  left: -10%;
  animation: cloudThree 12s ease-in-out infinite;
  background-image: url(${cloudThree});  

  &.clouds-zoomed-in-false {
    opacity: 0.025;    
  }
`;

const CloudFour = styled.div`
  ${cloud}
  ${animatedCloud}
  bottom: -10%;
  right: -10%;
  animation: cloudFour 15s ease-in-out infinite;
  background-image: url(${cloudFour});  

  &.clouds-zoomed-in-false {
    opacity: 0.025;    
  }
`;

const CloudFive = styled.div`
  ${cloud}
  ${animatedCloud}
  bottom: 0%;
  left: 20%;
  animation: cloudFive 10s ease-in-out infinite;
  background-image: url(${cloudFive});  

  &.clouds-zoomed-in-false {
    opacity: 0.05;
  }
`;
