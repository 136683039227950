import { BrowserRouter, Routes, Route } from "react-router-dom";

import React, { useEffect } from "react";
import { application as data } from "./data/data";

import Notification from "./components/block/Notification";
import Layout from "./components/block/Layout";
import NotFound from "./screens/NotFound";

import Game from "./screens/Game";
import PrivacyPolicy from "./components/block/PrivacyPolicy";
import TagManager from "react-gtm-module";

function App() {  
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-57HL5TR5",
      events: {
        view_course: "view_course",
      },
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={data.routes.home} element={<Game />} />
          <Route path={data.routes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Notification />
    </BrowserRouter>
  );
}

export default App;
