import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import CheckCircleWhiteOnBrown from "../../images/icons/inline/CheckCircleWhiteOnBrown";
import ContentLockedIcon from "../../images/icons/inline/ContentLockedIcon";
import VideoPlayIcon from "../../images/icons/inline/VideoPalyIcon";

import { content } from "../../data/data";

export default function SupplementalOverviewMarker(props) {
  const [allPrerequisitesComplete, setAllPrerequisitesComplete] =
    useState(false);
  const [transitionDelay, setTransitionDelay] = useState();
  const application = useSelector((state) => state);

  const classNames = `
    map-is-zoomed-in-${props.mapIsZommedIn} 
    has-incomplete-prerequisites-${props.hasIncompletePrerequisites}
  `;

  useEffect(() => {
    const baseDelay = 300;

    if (props.mapIsZommedIn === true) {
      setTransitionDelay(`${baseDelay}ms`);
      return;
    }

    if (props.mapIsZommedIn === false) {
      setTransitionDelay(`${baseDelay * props.nonZeroIndex}ms`);
      return;
    }
  }, [props.nonZeroIndex, props.totalMarkers, props.mapIsZommedIn]);

  useEffect(() => {
    if (props.supplemental.prerequisiteLocalIds) {
      const prerequisiteLocalIds = props.supplemental.prerequisiteLocalIds;

      for (let i = 0; i < prerequisiteLocalIds.length; i++) {
        const completedPrerequisites = [];

        const prerequisiteCourses = content.courses.filter(
          (course) => prerequisiteLocalIds.includes(course.localId)
        );

        if (prerequisiteCourses) {
          for (let i = 0; i < prerequisiteCourses.length; i++) {
            if (prerequisiteCourses[i].hasPassed) {
              completedPrerequisites.push(prerequisiteCourses[i]);
            }
          }
        }

        if (completedPrerequisites.length === prerequisiteLocalIds.length) {
          setAllPrerequisitesComplete(true);
        }
      }
    }
  }, [props.supplemental.prerequisiteLocalIds, application.content.courses]);

  if (props.course.hasPassed === false || allPrerequisitesComplete === false) {
    return (
      <Container className={classNames}>
        <SupplementalOverviewIcon style={{ transitionDelay: transitionDelay }}>
          <ContentLockedIcon />
        </SupplementalOverviewIcon>
      </Container>
    );
  }

  if (props.supplemental.hasPassed === true) {
    return (
      <Container className={classNames}>
        <SupplementalOverviewIcon style={{ transitionDelay: transitionDelay }}>
          <CheckCircleWhiteOnBrown />
        </SupplementalOverviewIcon>
      </Container>
    );
  }

  return (
    <Container className={classNames}>
      <SupplementalOverviewIcon style={{ transitionDelay: transitionDelay }}>
        <VideoPlayIcon />
      </SupplementalOverviewIcon>
    </Container>
  );
}

const SupplementalOverviewIcon = styled.div`    
  margin: 0 2px;
  opacity: 1;
  transition: opacity 0.3s linear;

  svg {
    width: 30px;
  }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;

  &.has-incomplete-prerequisites-false,
  &.map-is-zoomed-in-true {
    ${SupplementalOverviewIcon} {
      opacity: 0;
    }
  }
`;
