import React from "react";
import styled from "styled-components";
import Map from "./Map";

export default function Board(props) {
  return (
    <Section>
      <Map />
    </Section>
  );
}

const Section = styled.section``;
