import { openNotificationAsync } from "../../features/notification/notificationSlice";
import { openSidebarWithContentAsync } from "../../features/map/mapSlice";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ContentCompleteIcon from "../../images/icons/inline/ContentCompleteIcon";
import ContentLockedIcon from "../../images/icons/inline/ContentLockedIcon";
import VideoPlayIcon from "../../images/icons/inline/VideoPalyIcon";

import { content } from "../../data/data";

export default function SupplementalContentMarker(props) {
  const [allPrerequisitesComplete, setAllPrerequisitesComplete] =
    useState(false);
  const [incompletePrerequisites, setIncompletePrerequisites] = useState([]);
  const [transitionDelay, setTransitionDelay] = useState();
  const application = useSelector((state) => state);

  const dispatch = useDispatch();

  const classNames = `
    map-is-zoomed-in-${application.map.isZoomedIn} 
    map-is-zoomed-on-this-marker-${
      application.map.zommedInOnSectionWithLocalId === props.course.localId
    }    
  `;

  const translate = `
    translateX(${props.supplemental.marker.offsetLeft}) 
    translateY(${props.supplemental.marker.offsetTop})
  `;

  useEffect(() => {
    const baseDelay = 300;

    if (application.map.isZoomedIn === true) {
      setTransitionDelay(`${baseDelay}ms`);
      return;
    }

    if (application.map.isZoomedIn === false) {
      setTransitionDelay(`${baseDelay * props.nonZeroIndex}ms`);
      return;
    }
  }, [props.nonZeroIndex, props.totalMarkers, application.map.isZoomedIn]);

  const onUnlockedClick = () => {
    dispatch(openSidebarWithContentAsync(props.supplemental));
  };

  const onLockedClick = () => {    
    if (props.course.hasPassed === false) {
      dispatch(
        openNotificationAsync({
          type: `incompleteParent`,
          heading: `${props.supplemental.title} video is locked.`,
          paragraph: `Looks like you'll need to complete ${props.course.title} before you can watch ${props.supplemental.title}. Click on the ${props.course.title} map pin or click on the button below to continue.`,
          button: {
            label: `Go to ${props.course.title}`,
            actionType: `openCourseInSidebar`,
            course: props.course,
          },
        })
      );
      return;
    } else if (incompletePrerequisites.length) {
      dispatch(
        openNotificationAsync({
          type: `incompleteParent`,
          heading: `${props.supplemental.title} video is locked.`,
          paragraph: `Looks like you'll need to complete ${incompletePrerequisites[0].title} before you can watch ${props.supplemental.title}. Click on the ${incompletePrerequisites[0].title} map pin or click on the button below to continue.`,
          button: {
            label: `Go to ${incompletePrerequisites[0].title}`,
            actionType: `openCourseInSidebar`,
            course: incompletePrerequisites[0],
          },
        })
      );
    }
  };

  useEffect(() => {    
    if (props.supplemental.prerequisiteLocalIds) {      
      const prerequisiteLocalIds = props.supplemental.prerequisiteLocalIds;            

      for (let i = 0; i < prerequisiteLocalIds.length; i++) {
        const incompletePrerequisites = [];
        const completedPrerequisites = [];        

        const prerequisiteCourses = content.courses.filter(
          (course) => prerequisiteLocalIds.includes(course.localId)
        );        

        if (prerequisiteCourses) {
          for (let i = 0; i < prerequisiteCourses.length; i++) {
            if (prerequisiteCourses[i].hasPassed) {
              completedPrerequisites.push(prerequisiteCourses[i]);
            } else {
              incompletePrerequisites.push(prerequisiteCourses[i]);
            }
          }
        }

        if (completedPrerequisites.length === prerequisiteLocalIds.length) {
          setAllPrerequisitesComplete(true);
        } else {
          setIncompletePrerequisites(incompletePrerequisites);
        }        
      }
    }
  }, [props.supplemental.prerequisiteLocalIds, application.content.courses]);

  if (props.course.hasPassed === false || allPrerequisitesComplete === false) {
    return (
      <Container className={classNames} style={{ transform: translate }}>
        <SupplementalContentIcon
          onClick={onLockedClick}
          style={{ transitionDelay: transitionDelay }}
        >
          <ContentLockedIcon />
          <IconLabel>{props.supplemental.title}</IconLabel>
        </SupplementalContentIcon>
      </Container>
    );
  }

  if (props.supplemental.hasPassed === true) {
    return (
      <Container className={classNames} style={{ transform: translate }}>
        <SupplementalContentIcon
          onClick={onUnlockedClick}
          style={{ transitionDelay: transitionDelay }}
        >
          <ContentCompleteIcon />
          <IconLabel>{props.supplemental.title}</IconLabel>
        </SupplementalContentIcon>
      </Container>
    );
  }

  return (
    <Container className={classNames} style={{ transform: translate }}>
      <SupplementalContentIcon
        onClick={onUnlockedClick}
        style={{ transitionDelay: transitionDelay }}
      >
        <VideoPlayIcon />
        <IconLabel>{props.supplemental.title}</IconLabel>
      </SupplementalContentIcon>
    </Container>
  );
}

const SupplementalContentIcon = styled.div`
  margin: 0 2px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  transform: translate3d(0, 0, 10px);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 30px;
  }
`;

const Container = styled.div`
  display: inline-block;
  pointer-events: none;

  &.map-is-zoomed-in-true.map-is-zoomed-on-this-marker-true {
    ${SupplementalContentIcon} {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

const IconLabel = styled.span`
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  max-width: 125px;
  text-shadow: 1px 1px 3px #efefef;
`;
